import React from 'react';
import Box from './Box';

const Grid = ({ rows, columns, boxes, setBoxes, names }) => {
  const generateGrid = () => {
  const gridItems = [];
  const cellSize = 50;

  for (let i = 0; i < rows * columns; i++) {
    const box = boxes[i] || { position: `${i + 1}`, name: '' }; // Lägg till en dummy box om ingen box finns för positionen
    gridItems.push(
      <div
        key={`grid-item-${i}`}
        className="grid-item"
        style={{
          width: `${cellSize}px`,
          height: `${cellSize}px`,
          outline: '1px solid black',
          boxSizing: 'border-box',
        }}
      >
        <Box
          key={`box-${i}`}
          id={`box-${i}`}
          position={box.position}
          boxes={boxes}
          setBoxes={setBoxes}
          name={box.name}
        />
      </div>
    );
  }

  return gridItems;
};


  const generateBoxes = () => {
    return boxes.map((box, index) => (
      <Box
        key={`box-${index}`}
        id={`box-${index}`}
        position={box.position}
        boxes={boxes}
        setBoxes={setBoxes}
        name={box.name}
      />
    ));
  };

  return (
    <div className="grid-outer-container" style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="grid-container" style={{ display: 'grid', gridTemplateColumns: `repeat(${columns}, 1fr)`, gridTemplateRows: `repeat(${rows}, 1fr)`, gap: '5px', width: `${columns * 50 + (columns - 1) * 5}px`, }}>
        {generateGrid()}
        {generateBoxes()}
      </div>
    </div>
  );
};

export default Grid;